<template>
  <transition name="slide-left">
    <div ref="menuDom" v-if="visible" :class="['menu-sider', { 'nav-bar-transparent': visible }]">
      <svg class="icon-close" width="26" height="26" @click="hideMainMenu">
        <use href="../assets/icon.svg#close-lg" />
      </svg>
      <ul class="menu-container">
        <template v-for="item in mainMenu" :key="item">
          <li :class="{ active: item.active }" @mouseenter="item.active = true" @mouseleave="item.active = false">
            <div class="titleBox">
              <a :href="item.href" @click="clickA(E)" v-if="item.href != '#'">{{ item.text }}</a>
              <template v-else>
                {{ item.text }}
              </template>
              <MenuArrow />
            </div>
            <TransitionHeight :show="item.active">
              <ul class="menu-sub">
                <li v-for="it in item.children" :key="it">
                  <a :href="it.href" v-if="item.href != '#'">{{ it.text }}</a>
                  <template v-else>
                    {{ item.text }}
                  </template>
                </li>
              </ul>
            </TransitionHeight>
          </li>
        </template>
      </ul>
      <div class="menuDom-button">
        <div class="menuDom-button-wechat">
          <div style="margin-right: 8px;">营业时间：周一至周六 08:00-18:00</div>
        </div>
        <div class="menuDom-button-Hotline">
          <div style="margin-right: 8px;">服务电话：86-760-2226 2717</div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import StaticImage from "@/components/StaticImage.vue"
import MenuArrow from './icons/MenuArrow.vue';
import TransitionHeight from './transition-height.vue';
import router from '../router.js'


export default {
  name: 'MenuSider',
  props: {
    visible: Boolean,
    name: String,
  },
  components: {
        StaticImage,
    MenuArrow,
    TransitionHeight
  },
  data() {
    return {
      menuDom: null,
      activeMenuHref: '',
      routerPath: '',
      mainMenu: [
        {
          text: '关于Realsee', href: '/AboutRealsee',active: false,
          children: [
            { text: '品牌', href: '/AboutRealsee#brand', },
            { text: '发展', href: '/AboutRealsee#develop', },
            { text: '服务领域', href: '/AboutRealsee#ServiceArea', },
            { text: '加入Realsee', href: '/AboutRealsee#join', },
          ]
        },
        {
          text: '产品', href: '/product', active: false,
          children: [
            { text: '嵌装灯具', href: '/product-list/113', },
            { text: '明装灯具', href: '/product-list/114', },
            { text: '轨道灯具', href: '/product-list/116', },
            { text: '壁灯', href: '/product-list/118', },
            { text: '吊线灯', href: '/product-list/119', },
            { text: '地角灯', href: '/product-list/120', },
            // { text: '地埋灯', href: '/product-list/121', },
            { text: '线性灯具', href: '/product-list/115', },
            { text: '洗墙灯', href: '/product-list/122', },
            { text: '公建项目灯具', href: '/product-list/125', },

            { text: '电源', href: '/product-list/126', },
            { text: 'Plugin 模组系列', href: '/product-list/127', },
            { text: '雪石预装', href: '/product-list/128', },
            { text: '智能系统', href: '/product-list/129', },
            { text: '户外产品', href: '/product-list/121', },
          ]
        },
        { text: '工程实例', href: '/engineering-example', active: false, },
        { text: '资讯', href: '/News', active: false, },
        {
          text: '下载',
          href: '/DownloadCenter',
          active: false,
          // active: this.$route.path=='/DownloadCenter' ? true : false,
          children: [
            { text: '画册型录', href: '/DownloadCenter#picturealbum', },
            { text: '安装说明', href: '/DownloadCenter#picturealbum', },
          ]
        },
        {
          text: '联系我们',
          href: '/ContactUs',
          // active: this.$route.path=='/ContactUs' ? true : false,
          active: false,
          children: [
            { text: '留言', href: '/ContactUs#about', },
            { text: '致电或其他', href: '#/ContactUsCall', },
          ]
        },
      ]
    }
  },
  mounted() {
    router.afterEach( () => {
        this.routerPath = router.currentRoute.value.fullPath || '' 
        this.activeDefaultMenu()
    })
    document.body.addEventListener('click', (e) => {
      if (document.querySelector('.main-menu-icon').contains(e.target)) {
        return
      }
      let menuDom = this.menuDom || this.$refs.menuDom;
      if (!menuDom) {
        return;
      }
      if (!menuDom.contains(e.target)) {
        this.hideMainMenu()
      }
    })

  },
  methods: {
    hideMainMenu() {
      this.$emit('visible', false)
    },
    hideclick(item) {
      // item.active = !item.active
    },
    clickA(e) {
      console.log(e);
    },
    activeDefaultMenu() {
      for (let item of this.mainMenu) {
        item.active = this.routerPath.startsWith(item.href)
      }
    }
  }
}
</script>

<style lang="scss">
.icon-close {
  cursor: pointer;
  padding: 4px;
}

.icon-close:hover {
  color: #fff;
}

.menu-sider {
  position: relative;

  .menuDom-button {
    position: absolute;
    left: 0;
    bottom: 0;
    padding: 30px;

    .menuDom-button-wechat {
      display: flex;
    }

    .menuDom-button-Hotline {
      display: flex;
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: max-height 0.5s ease-out;
}

.fade-enter-from,
.fade-leave-to {
  display: none;
}


.slide-left-enter-active,
.slide-left-leave-active {
  transition: transform 0.3s;
}

.slide-left-enter-from,
.slide-left-leave-to {
  transform: translateX(-100%);
}

/* .slide-left-enter-to,
.slide-left-leave {
  transform: translateX(0);
} */
</style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.menu-container,
.menu-container ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;

  user-select: none;
}
.titleBox{
  display: flex;
  justify-content: space-between;
}

.menu-container li {
  line-height: 56px;
  justify-content: space-between;
  align-items: center;
}

.menu-container li.active .titleBox a,
.menu-container li:hover .titleBox  a,
.menu-container li.active  .titleBox a {
  color: #fff;
  font-weight: 400;
}

ul.menu-sub {
  font-size: 13px;
  margin-top: -10px;
  padding-left: 2em;
}

.menu-sub li {
  line-height: 32px;
}

.menu-sider {
  background: rgba(19, 14, 18, 0.8);
  backdrop-filter: blur(2px);
  color: #aaa;
  position: fixed;
  height: 100vh;
  width: 380px;
  top: 0;
  left: 0;
  z-index: 1000;
  font-size: 14px;
  font-weight: 100;
  padding: 10px 30px;
}

.menu-sider a {
  color: #aaa;
  text-decoration: none;
}


.menu-sider a:hover,
.menu-sider a.active {
  color: #fff;
}
</style>
