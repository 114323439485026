import skrollr from 'skrollr';
import { createRouter, createWebHistory } from 'vue-router';
import HomePage from './HomePage.vue';
// import ProductPage from './ProductPage.vue';
// import ProductListPage from './ProductListPage.vue';
// import ProductDetail from './ProductDetail.vue';
// import AboutRealsee from "./AboutRealsee.vue"
// import ContactUs from "./ContactUs.vue"
// import EngineeringExamplePage from "./EngineeringExamplePage.vue"
// import EngineeringExampleDetailPage from "./EngineeringExampleDetailPage.vue"

const routes = [
  { path: "/", component: HomePage },
  { path: "/index.html", component: HomePage },
  { path: "/product", component: () => import("./ProductPage.vue") },
  { path: "/DownloadCenter", component: () => import("./DownloadCenter.vue") },
  { path: "/ContactUs", component: () => import("./ContactUs.vue") },
  { path: "/News", component: () => import("./NewsPage.vue") },
  { path: '/product-list', component: () => import("./ProductListPage.vue")  },
  { path: '/product-list/:code', component: () => import("./ProductListPage.vue")  },
  { path: '/product-detail/:code', component: () => import("./ProductDetail.vue") },
  { path: '/product-detail', component: () => import("./ProductDetail.vue") },
  { path: "/AboutRealsee", component: () => import("./AboutRealsee.vue") },
  { path: "/engineering-example", component: () => import("./EngineeringExamplePage.vue") },
  { path: "/engineering-example-detail/:id", component: () => import("./EngineeringExampleDetailPage.vue") },
];
const router = createRouter({
  history: createWebHistory(),
  routes: routes,
  scrollBehavior (to, from, savedPosition) {

    // let pos = false; // 不滚动
    let pos = savedPosition ? savedPosition : { top: 0 } // 返回到顶部

    if (to.hash) {
      pos = {
        el: to.hash,
        behavior: 'smooth', // 平滑滚动到位置
      }
    }

    return new Promise((resolve, reject) => {
      setTimeout(() => {
        console.log( 'scrollBehavior', to.hash );
        resolve(pos)
      }, 500) // 延迟滚动
    })
    
  }
});

// 预加载各页面
// setTimeout(() => {
//   for(let i in routes) {
//     if(routes[i].path != '/') routes[i].component();
//   }
// }, 2000);

router.afterEach(()=>{
  
})


export default router;
