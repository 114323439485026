<template>

<div class="container">
  <BrandImage />
  <EngineeringExamples v-if="show" />
  <EngineeringExampleItems  v-if="show"/>
  <SomePartners  v-if="show"/>
  <ProductService  v-if="show"/>
  <AreaService  v-if="show"/>
</div>
</template>

  
<script setup>
import { defineAsyncComponent ,onMounted,ref} from 'vue';
import BrandImage from './components/BrandImage.vue'
// import EngineeringExamples from './components/EngineeringExamples.vue'
// import EngineeringExampleItems from './components/EngineeringExampleItems.vue'
// import SomePartners from './components/SomePartners.vue'
// import ProductService from './components/ProductService.vue'
// import AreaService from './components/AreaService.vue'

// const BrandImage=defineAsyncComponent(()=>import("./components/BrandImage.vue"))
const EngineeringExamples=defineAsyncComponent(()=>import("./components/EngineeringExamples.vue"))
const EngineeringExampleItems=defineAsyncComponent(()=>import("./components/EngineeringExampleItems.vue"))
const SomePartners=defineAsyncComponent(()=>import("./components/SomePartners.vue"))
const ProductService=defineAsyncComponent(()=>import("./components/ProductService.vue"))
const AreaService=defineAsyncComponent(()=>import("./components/AreaService.vue"))



const show=ref(true)
onMounted(()=>{
  setTimeout(() => { show.value= true }, 300);
})

// export default {
//   name: 'HomePage',
//   components: {
//     BrandImage,
//     EngineeringExamples,
//     EngineeringExampleItems,
//     SomePartners,
//     ProductService,
//     AreaService,
//   },
//   data() {
//     return {
//       show: true,
//     }
//   },
//   methods: {
   
//   },
//   mounted() {
//     let me = this;
//     setTimeout(() => { me.show = true }, 800);
//   }
// }
</script>
<style scoped>
/* body{
  height: 100% !important;
} */
  .container {
    background-color: #fff;
    width: var(--width-100-vw);
    overflow: hidden;
}
.container > div {
    width: var(--width-100-vw);
    overflow: hidden;
}
</style>
<!-- <style>
body{
  height: 100% !important;
}
</style>
 -->