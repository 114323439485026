<template>
    <i class="menu-arrow">
        <svg t="1686985878934" class="menu-arrow-close icon" fill="currentColor" viewBox="0 0 1024 1024" version="1.1"
            xmlns="http://www.w3.org/2000/svg" p-id="1463" xmlns:xlink="http://www.w3.org/1999/xlink" width="16"
            height="16">
            <path
                d="M601.6 228.266667h91.733333c6.4 0 10.666667 2.133333 12.8 6.4 2.133333 4.266667 0 8.533333-4.266666 12.8l-264.533334 264.533333 264.533334 264.533333c4.266667 4.266667 6.4 8.533333 4.266666 12.8s-6.4 6.4-12.8 6.4h-91.733333c-4.266667 0-8.533333-2.133333-12.8-4.266666l-268.8-268.8c-2.133333-2.133333-4.266667-6.4-4.266667-10.666667s2.133333-6.4 4.266667-10.666667l268.8-268.8c4.266667-2.133333 8.533333-4.266667 12.8-4.266666z"
                p-id="1464"></path>
        </svg>
    </i>
</template>
  
<script>

export default {
    name: 'MenuArrow',
    props: {
        title: String
    },
    data: () => {
        return {
        }
    }
}
</script>

<style>
.active .menu-arrow .icon {
    display: inline;
    transform: rotate(270deg);
    transition: all 0.3s ease-out;
}
li .menu-arrow .icon {
    display: inline;
    transform: rotate(180deg);
    transition: all 0.3s ease-out;
}

</style>
