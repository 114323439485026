import { createApp } from "vue";
import App from "./App.vue";
import router from "./router.js";
import VueLazyload from "vue-lazyload";
import "./assets/icon/iconfont.css"

import 'swiper/css'
import 'swiper/css/pagination'

import "./assets/css/index.scss";

const app = createApp(App);

app.use(router);

app.use(VueLazyload, {
  // loading: 'path/to/loading.gif', // 加载中显示的占位图
  // error: 'path/to/error.png', // 加载失败时显示的占位图
  // attempt: 3, // 加载失败时的重试次数
});
app.mount("#app");

