<template>
  <div class="brand-image">
    <!-- <StaticImage style="object-fit: cover;" :src="img" :onload="preloadImages" /> -->

    <video loop autoplay muted style=" object-fit: cover; width: 100%; pointer-events: none;aspect-ratio: 16 / 9;z-index: -1;" v-if="img.endsWith('.webm')" >
        <source :src="imageFullSrc(img)" type="video/webm">
    </video>

    <video loop autoplay muted style=" object-fit: cover; width: 100%; pointer-events: none;aspect-ratio: 16 / 9;z-index: -1;" v-if="img.endsWith('.mp4')" >
        <source :src="imageFullSrc(img)" type="video/mp4">
    </video>

    <Swiper class="swiper head-banner" :modules="modules" :effect="'fade'" :fadeEffect="{crossFade:true}"
        :autoplay="{ delay: 3000, disableOnInteraction:false }" :navigation="false" :pagination="false" :style="{
            '--swiper-navigation-color': '#fff',
            '--swiper-pagination-color': '#c9c9ca',
            '--swiper-pagination-bullet-inactive-color': '#ccc',
            '--swiper-pagination-bullet-width': '50px',
            '--swiper-pagination-bullet-height': '1px',
            '--swiper-pagination-bullet-border-radius': '0',
            '--swiper-pagination-bullet-inactive-opacity': '0.5',
        }" :loop="true" :speed="2000" :observeParents="true" :observe="true" :observeSlideChildren="true"
        v-if="items.length > 1 && img == ''"
        >
        <SwiperSlide v-for="(item, index) in items" :key="item">
            <StaticImage :src="item" :alt="index"/>
        </SwiperSlide>
    </Swiper>

  </div>
</template>

<script>
import StaticImage from "@/components/StaticImage.vue"
import { getHomePageSection, imageFullSrc } from '@/utils/http';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Autoplay, Pagination, Navigation, EffectFade, EffectCoverflow } from 'swiper';

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/autoplay'
import 'swiper/css/effect-fade'
import 'swiper/css/pagination'


export default {
  name: 'BrandImage',
  components: {
    StaticImage, Autoplay, Pagination, Navigation, EffectFade, EffectCoverflow, SwiperSlide, Swiper
  },
  props: {},
  data: () => {
    return {
      img: "",
      items: [],
      preload: [
        '/assets/room-1.jpeg',
        '/assets/example-big-2.jpeg',
        '/assets/example-big.jpeg',
        '/assets/map.png',
      ],
    }
  },
  mounted() {
    getHomePageSection("首页顶部banner").then(data => {
      this.img = data?.cover || "/assets/banner-large.jpeg"
      this.items = data?.media || []
    })

    setTimeout(()=>{
      let play = function(){
          document.querySelector('.brand-image video').play();
          document.removeEventListener("click",play,false);
          document.removeEventListener("touchstart",play,false);
          document.removeEventListener("WeixinJSBridgeReady",play,false);
      }
      document.body.addEventListener('click',play,false);
      document.body.addEventListener('touchstart',play,false);
      document.body.addEventListener('WeixinJSBridgeReady',play,false);
    }, 0)

  },
  methods: {
    preloadImages() {
      // for (let url of this.preload) {
      //   var img3 = new Image();
      //   img3.src = imageHost + url;
      // }
    },
    imageFullSrc
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.brand-image {
  aspect-ratio: 16 / 9;
  max-width: 100%;
  text-align: center;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAB4AAAAQ4AQMAAADSHVMAAAAAA1BMVEUAAACnej3aAAABE0lEQVR4Xu3BAQ0AAADCoPdPbQ8HFAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAwKcB+OUAAejagTMAAAAASUVORK5CYII=');
}

.brand-image img {
  height: 100%;
  aspect-ratio: 16 / 9;
}

.preload-images {
  width: 0px;
  height: 0px;
}
</style>
