
<template >
    <div class="sub-menu-container" ref="container">
        <slot>
        </slot>
    </div>
</template >
 
<script setup>
import {nextTick, ref, defineProps, onMounted, watch} from "vue"

const props = defineProps({
    show: {
        type: Boolean,
        default: true,
    },
})

const height = ref(0)
const container = ref(null)

onMounted(() => {
    nextTick( () => {
        height.value = container.value.offsetHeight;
        container.value.style.height = props.show ? `${height.value} px` : 0;
    })
})

watch( ()=>props.show, (newVal)=>{
    let h = height.value.toString() + 'px'
    container.value.style.height = newVal ? h : 0;
})
</script >
 
<style scoped >
.sub-menu-container {
    transition: height .4s ease;
    overflow: hidden;
}
</style >
