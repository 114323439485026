<template>
    
    <div style="position: fixed; bottom:260px;left:0px;min-height: 50px;font-size: 9px;width:100%;overflow-x:hidden">
        <div style="width:1920px;display:inline-block;border-top: solid 4px black;">
        <div style="width:1536px;display:inline-block;border-top: solid 4px red;">
        <div style="width:1280px;display:inline-block;border-top: solid 4px black;">
        <div style="width:1024px;display:inline-block;border-top: solid 4px red;">
        <div style="width:768px;display:inline-block;border-top: solid 4px black;">
            <div style="width:640px;display:inline-block;border-top: solid 4px red;text-align: right;">640px</div>
            <div style="height: 50px;"></div>
        </div>768px
        </div>1024px
        </div>1280px
        </div>1536px
        </div>1920px
    </div>

</template>

<script>
import StaticImage from "@/components/StaticImage.vue"
export default {
  name: 'ScreenGrid',
}
</script>