<template>
  <MenuSider :visible="expand" v-on:visible="onVisible" :name="name" />
  <div class="nav-bar hi" id="nav-bar">
      <div><span v-show="!expand"><svg @click="expand=true" class="main-menu-icon size" width="26" height="26"><use href="../assets/icon.svg#menu" /></svg></span></div>
      <div><a href="/"> <svg class="size" width="79" height="18"><use href="../assets/icon.svg#logo" /></svg>&nbsp;</a></div>
      <div><a href="/"> <svg class="size" width="18" height="18"><use href="../assets/icon.svg#user" /></svg> 登录/注册</a></div>
      <div><a href="/"> <svg class="size" width="18" height="18"><use href="../assets/icon.svg#shopping-cart" /></svg> 商城</a></div>
      <div><a href="/"> <svg class="size" width="16" height="16"><use href="../assets/icon.svg#global" /></svg> CN</a></div>
  </div>
</template>

<script>
import StaticImage from "@/components/StaticImage.vue"
import MenuSider from './MenuSider.vue'

export default {
  name: 'NavBar',
  components: {
        StaticImage,
    MenuSider
  },
  props: {
    name: String
  },
  data() {
    return {
      expand: false,
    }
  },
  mounted() {
    // 监听页面滚动事件
    window.addEventListener("scroll", this.scrolling)
  },
  methods: {
    // 头部导航做鼠标滚动优化
    scrolling() {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop

      if(scrollTop > 10) {
        document.querySelector('#nav-bar').classList.replace('hi', 'lo');
      } else {
        document.querySelector('#nav-bar').classList.replace('lo', 'hi');
      }
    },
    onVisible(v) {
      this.expand = v
    },
  }
}
</script>

<style>

.main-menu-icon {
  cursor:pointer;
  padding: 4px;
}
.main-menu-icon:hover {
  color: rgba(255,255,255,0.9);
}

.nav-bar.hi {
  background-color: rgba(19, 14, 18, 0.75);
  height: 44px;
  transition-duration: 300ms;
  opacity: 1;
  visibility: inherit;;
}

.nav-bar.lo {
  background-color: rgba(19, 14, 18, 0.55);
  height: 44px;
  transition-duration: 300ms;
  /* opacity: 0; */
  /* visibility: hidden; */
}
</style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.nav-bar {
  width: var(--width-100-vw);
  display: flex;
  column-gap: 10px;
  align-items: center;
  position: fixed;
  /* left: 0; */
  top: 0;
  /* color: #88868a; */
  color: rgba(255,255,255,0.6);
  padding: 0 30px;
  column-gap: 50px;
  z-index: 999;
  box-sizing: border-box;
  font-size: 14px;
  /* height: 80px; */
}

.nav-bar a {
  display: flex;
  align-items: center;
}
.nav-bar a svg{
  margin:4px;
}

.nav-bar div:first-child {
  flex: 1;
}

.nav-bar a {
    text-decoration: none;
    color:rgba(255,255,255,0.5)
}

.nav-bar a:visited {
    color:rgba(255,255,255,0.5)
}

.nav-bar a:hover {
    color: rgba(255,255,255,0.9);
}

</style>

<style>
a:visited {
  color: initial
}
</style>