import axios from 'axios';
import fsdpf from 'fsdpf';
import { trim, memoize} from 'lodash-es';

export const apiHost = 'https://new.realsee.cc:8000/'

// export const imageHost = 'https://new.realsee.cc'
export const imageHost = 'https://realsee-web.oss-cn-shenzhen.aliyuncs.com'

const core = fsdpf.getInstance("realsee", "vSKgO5Ndz2PLIqWCU8FVw3JijDhfMQpRrmZxcXunosTBtHG7k4a6AEe9lybY10", "https://new.realsee.cc:8000/")

const http = ({ method, url, params = {}, data = {}, ...options }) =>
    axios({ method, url: `https://new.realsee.cc/${trim(url, '/')}`, params, data, ...options })
        .then(({ data: { code, msg, data } }) => {
            if (code) {
                throw msg
            }
            return data
        })

export const get = memoize((url, params = {}) => http({ method: 'GET', url, params }), (args) => JSON.stringify(args))

export const getPageSection = (id, ...sections) => get(`/api/page/${id}`).then(({ PageSection }) => {
    if (sections.length > 1) {
        return PageSection.filter(item => sections.includes(item?.name)).sort((a, b) => {
            const indexA = sections.indexOf(a?.name);
            const indexB = sections.indexOf(b?.name);

            if (indexA === -1 && indexB === -1) {
                // 如果两个元素都不在指定顺序数组中，则保持原来的顺序
                return 0;
            }

            if (indexA === -1) {
                return 1; // a.name 不在指定顺序数组中，将其排在后面
            }

            if (indexB === -1) {
                return -1; // b.name 不在指定顺序数组中，将其排在前面
            }

            return indexA - indexB;
        })
    }
    return PageSection.find(item => sections.includes(item?.name))
})

export const getHomePageSection = (...sections) => getPageSection(1, ...sections)

export const getAboutRealseeSection = (...sections) => getPageSection(2, ...sections)

export const getProductPageSection = (...sections) => getPageSection(3, ...sections)
export const getProductListPageSection = (...sections) => getPageSection(8, ...sections)

export const getProductDetailPageSection = (...sections) => getPageSection(9, ...sections)

export const getNewsPageSection = (...sections) => getPageSection(10, ...sections)
export const getEnginerPageSection = (...sections) => getPageSection(4, ...sections)
export const getEnginerList = (params) => core.get(`/api/case-list/${core.encode(params)}`)
export const getDownloadResourceList = (params) => core.get(`/api/download-list/${core.encode(params)}`)
// export const getEnginerList = () => core.get('/api/case-items')
export const getEnginerCategoryList = () => get('/api/product-props-options/5')
export const getEnginerItem = (itemId) => get('/api/case-item/' + itemId)

export const getHomeCaseItems = () => get('/api/home-case-items')

export const getHomePartnerItems = () => get('/api/home-partner-items')

export const getAboutMilestoneItems = () => get('/api/about-milestone-items')

export const getNewsItems = () => get('/api/news-items')

export const getContactUs = (...sections) => getPageSection(6, ...sections)

export const getDownloadCenter = (...sections) => getPageSection(7, ...sections)

export const saveContactUs = (Feekback) => core.post('/api/feedback-form/0', { Feekback })

export const getProductList = (params) => core.get(`/api/product-list/${core.encode(params)}`)
export const getProductDetail = (productCode) => get('/api/product-item/' + productCode)

export const imageFullSrc = (src)=>{
    if((src || '') == '') {
        return 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';
    }
    if(src.startsWith('http://') || src.startsWith('https://') || src.startsWith('data:')) {
        return src;
    }
    if(src.startsWith('/')) {
        return imageHost + src
    }
    return imageHost + '/' + src
}

export default http