<template>
  <router-view v-slot="{ Component }">
    <div>
    <NavBar :name="router.currentRoute.path"/>
    <!-- 全局动画 -->
    <transition name="fade" appear >
      <!-- 动态缓存 -->
      <keep-alive :max="10">
        <component :is="Component"></component>
      </keep-alive>
    </transition>
  <PageFooter />
  <ScreenGrid v-if="false" />
  </div>
  </router-view>
</template>

<script>
import NavBar from './components/NavBar.vue'
import PageFooter from './components/PageFooter.vue'
import ScreenGrid from './components/grid.vue'
import router from './router.js'

export default {
  name: 'App',
  components: {
    NavBar,
    PageFooter,
    ScreenGrid,
  },
  data() {
    return {
      current :'/',
      router: router
    }
  },
  mounted() {
    document.querySelector("body").classList.remove('infinite')
    setTimeout(()=>{
      document.querySelector("body").classList.remove('dark')
      const box = document.querySelector("#addbox")
      if(box) box.remove()
    }, 2000)
  },
}
</script>

<style>
:root {
  --container-width: 100%;
}

@media (max-width: 1920px) {
  :root {
    --container-width: 1920px;
  }
}

@media (max-width: 1280px) {
  :root {
    --container-width: 1280px;
  }
}

@media (max-width: 1024px) {
  :root {
    --container-width: 1024px;
  }
}


body {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", "PingFang SC", "Microsoft YaHei", "Helvetica Neue", "Hiragino Sans GB", "WenQuanYi Micro Hei", Arial, sans-serif;
}

#app {
  max-width: var(--container-width);
  margin: 0 auto;
  /* background-color: #fff; */
}

.float-clear {
  clear: both;
}

*::-webkit-scrollbar-thumb {
  background: #aaa;
  border-radius: 5px;

}

*::-webkit-scrollbar-thumb:hover {
  background: #999;
}

*::-webkit-scrollbar-track {
  background-color: #ddd;
  box-shadow: 5px #555;
}

*::-webkit-scrollbar-corner {
  background-color: #ddd;
}

*::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

* {
  box-sizing: border-box;
}


/* 进入之前的动画 */
.fade-enter-from {
  /* 透明度 */
  /* -webkit-transform: translateX(-200px) scale(1); */
    /* transform: translateX(-200px) scale(1); */
    opacity: 0;
}

/* 进入之后的动画 */
.fade-enter-to {
  /* -webkit-transform: scale(1); */
    /* transform: scale(1); */
    opacity: 1;
}

/* 离开之前的动画 */
.fade-leave-from {
  /* -webkit-transform: scale(1); */
    /* transform: scale(1); */
    opacity: 1;
}

/* 离开之后的动画 */
.fade-leave-to {
  /* -webkit-transform: translateX(200px) scale(1); */
    /* transform: translateX(200px) scale(1); */
    opacity: 0;
}

/* 进入动画时常和离开动画时常 */
.fade-enter-active,
.fade-leave-active {
  transition-duration: 800ms;
}

/* 延迟0.3秒在进入动画 */
.fade-enter-active {
  transition-delay: 500ms;
}
</style>
