<template>
<img :src="imageSrc" :alt="props.alt" :loading="loading"/>
</template>

<script setup>
import { imageFullSrc } from "@/utils/http"
import { defineProps, computed } from "vue"

const props = defineProps({
    src: String,
    loading: String,
    alt: {
        type: String,
        default: ''
    }
})

const imageSrc = computed(()=>{
    return imageFullSrc(props.src);
})

</script>