<template>
    <div class="footer hidden">
        <div class="footer-container">
            <div class="qr-block">
                <div>
                    <a href="/"><svg class="size" width="100" height="30"><use href="../assets/release-logo-b.svg#logo-b" /></svg></a>
                    <a href="/"><svg class="size" width="60" height="60"><use href="../assets/qr.svg#qr" /></svg></a>
                </div>
            </div>
            <div class="categories">
                <div>
                    <span><a href="/">首页</a></span>
                    <a href="/AboutRealsee">关于丽舍辰光</a>
                    <span>加入我们</span>
                </div>
                <div>
                    <span><a href="/product">产品</a></span>
                    <span>室内照明</span>
                    <span>户外照明</span>
                    <span>商业照明</span>
                    <span>互联智控</span>
                    <span>配件</span>
                </div>
                <div>
                    <span><a href="/engineering-example">项目与案例</a></span>
                    <span>城市基建项目</span>
                    <span>博览展馆与博物馆</span>
                    <span>酒店</span>
                    <span>超市卖场与连锁</span>
                    <span>地产与私宅</span>
                </div>
                <div>
                    <span><a href="/News">资讯</a></span>
                    <span>行业资讯</span>
                </div>
                <div>
                    <span><a href="/DownloadCenter">下载</a></span>
                    <span>产品目录与图册</span>
                    <span>视频</span>
                    <span>安装说明书</span>
                    <span>其它</span>
                </div>
                <div>
                    <span><a href="/ContactUs">联系我们</a></span>
                    <span>留言</span>
                    <span>致电或其他</span>
                </div>
            </div>
            <div class="addr-block">
                <span class="media">
                    <a href="/"> <svg class="size" width="22" height="22"><use href="../assets/icon.svg#wechat" /></svg></a>
                    <a href="/"> <svg class="size" width="22" height="22"><use href="../assets/icon.svg#weibo" /></svg></a>
                    <a href="/"> <svg class="size" width="22" height="22"><use href="../assets/icon.svg#twitter" /></svg></a>
                    <a href="/"> <svg class="size" width="22" height="22"><use href="../assets/icon.svg#facebook" /></svg></a>
                </span>
                <span>www.realsee.cc</span>
                <span>86 - 0755 - 2964 6656 / 2226 2717</span>
                <span>版权所有©深圳市丽舍辰光照明有限公司</span>
                <span>粤ICP备14026764号-1</span>
            </div>
        </div>
    </div>
</template>
  
<script>
import StaticImage from "@/components/StaticImage.vue"

export default {
    name: 'PageFooter',
    props: {},
    components: {
        StaticImage
    },
    mounted() {
        setTimeout( ()=> document.querySelector('.footer.hidden').classList.remove('hidden'), 1000 )
    },
    data() {
        return {
        }
    },
    methods: {

    }
}
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.footer {
    min-height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f7f7f8;
    color: #717171;
    font-size: 12px;
}

.footer .categories a {
    text-decoration: none;
}

.categories a:visited, .categories a:link, .categories a:visited, .categories a:focus {
    color: #717171;
}

.qr-block a:visited, .qr-block a:link, .qr-block a:focus {
    color: #5F5E5E
}

.qr-block a:hover {
    color: #A88A67;
}
.footer .categories a:hover {
    /* text-decoration: underline; */
    color: #A88A67;
    padding-left: 1px;
    /* text-underline-offset: 4px; */
}


.footer .categories a {
    position: relative;
    display:inline-block;
}

.footer .categories a::before {
    content: "";
    position: absolute;
    top: 0px;
    left: -5px;
    width:  0%;
    height: 0%;
    border-left: 2px solid #A88A67;
    opacity: 0;
    transition: 0.2s all linear;
}

.footer .categories a:hover::before {
    opacity: 1;
    height: 100%;
}

.footer .footer-container {
    /* width: 80%; */
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
}

.footer .footer-container>div {
    display: flex;
}

.qr-block,
.addr-block {
    width: 25%;
}

.footer .footer-container .categories {
    flex: 1;
    column-gap: 14px;
    line-height: 20px;
    justify-content: space-around
}

.footer .footer-container .categories>div {
    display: flex;
    flex-direction: column;
}

.footer .footer-container .categories>div>span:first-child,
.addr-block>span:first-child {
    margin-bottom: 18px;
}

.qr-block {
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.qr-block>div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 110px;
    /* align-items: center; */
}

.addr-block {
    flex-direction: column;
    /* align-items: flex-end; */
}

.footer-container .categories {
    margin: 40px;
}

.addr-block>.media {
    color: #717171;
}

.addr-block>.media> a {
    padding: 4px;
    color: #717171;
    transition-duration: 300ms;
}

.addr-block>.media> a:hover {
    padding: 4px;
    color: #A88A67;
    transform-origin: center;
    transform: scale(1.05);
}

.addr-block>.media  svg {
    transition-duration: 300ms;
    transform: scale(1.);
    /* zoom:100% */
}
.addr-block>.media  svg:hover {
    transition-duration: 300ms;
    transform: scale(1.05);
    /* zoom:105% */
}

/* xl */
@media (min-width: 1280px) {
    .footer .footer-container {
        --width-type: 'xl';
        width: 90%;
    }

    .qr-block {
        width: 200px;
    }

    .addr-block {
        width: 25%;
    }

    .footer-container .categories {
        border-left: 1px solid #b3b3b3;
        border-right: 1px solid #b3b3b3;
        padding: 0px 60px 0px 20px;
        margin: 20px;
    }

    .addr-block {
        align-items: flex-end;
        margin-right: 20px;
    }
}

/* lg */
@media (min-width: 1024px) and (max-width: 1279px) {
    .footer .footer-container {
        --width-type: 'lg';
        width: 80%;
    }

    .qr-block {
        order: 1;
        width: 50%;
        margin-bottom: 40px;
    }

    .categories {
        order: 0;
        min-width: 80%;
    }

    .addr-block {
        order: 2;
        width: 50%;
        align-items: flex-start;
        margin-bottom: 40px;
    }
}

/* md */
@media (min-width: 768px) and (max-width: 1023px) {
    .footer .footer-container {
        --width-type: 'md';
        width: 100%;
    }

    .qr-block {
        order: 1;
        width: 50%;
    }

    .categories {
        order: 0;
        min-width: 80%;
        margin-bottom: 40px;
    }

    .addr-block {
        order: 2;
        width: 50%;
        align-items: flex-start;
        margin-bottom: 40px;
    }
}

/* sm */
@media (min-width: 640px) and (max-width: 767px) {
    .footer .footer-container {
        --width-type: 'sm';
        width: 100%;
    }

    .qr-block {
        order: 1;
        width: 50%;
        margin-bottom: 40px;
    }

    .categories {
        order: 0;
        min-width: 80%;
    }

    .addr-block {
        order: 2;
        width: 50%;
        align-items: flex-start;
        margin-bottom: 40px;
    }
}

@media (max-width: 639px) {
    .footer .footer-container {
        --width-type: 'sm';
        width: 100%;
    }

    .qr-block {
        order: 1;
        width: 50%;
    }

    .categories {
        order: 0;
        min-width: 80%;
    }

    .addr-block {
        order: 2;
        width: 50%;
        align-items: flex-start;
        padding: 40px 0;
    }
}
</style>
  